
import { _Client } from '@/api-client';
import { LeftOutlined, PlusOutlined, ExclamationCircleOutlined,CalendarOutlined  } from '@ant-design/icons-vue';
import { message, Form, FormInstance } from "ant-design-vue";
import Pagination from '@/components/Pagination/index.vue';
import {
    PagedResultOfSpeakingMonitorRecordsDto,
    SearchMonitorRecordsByExaminerRequest,
    PagedResultOfLifeSkillMonitorRecordsDto,
    PagedResultOfODPMonitorRecordsDto,
    DeleteMonitorRecordRequest,
    ExaminerModel,
    FollowUpRequest
} from '@/api-client/client';
import {
    ref,
    defineComponent,
    unref,
    onMounted,
    reactive
} from "vue";
import { useRouter } from 'vue-router';
import dayjs from 'dayjs';
import { RefSymbol } from '@vue/reactivity';
import { name } from '@azure/msal-browser/dist/packageMetadata';

//// MonitorRecords  主列表（考官列表）
const speakingMonitorRecordsColumns = [
    {
        title: 'Monitor Date',
        dataIndex: 'monitorDateStr',
    },
    {
        title: 'Monitor Stage',
        dataIndex: 'monitorStage'
    },
    {
        title: 'IDP',
        dataIndex: 'idpStr'
    },
    {
        title: 'Monitor Type',
        dataIndex: 'monitorType'
    },
    {
        title: 'Final Result',
        dataIndex: 'finalResult'
    },
    {
        title: 'Proceduress result',
        dataIndex: 'proceduresResult'
    }
    ,
    {
        title: 'Rating result',
        dataIndex: 'ratingResult'
    },
    {
        title: 'Follow up completed',
        dataIndex: 'followupCompletedStr'
    },
    {
        title: 'Follow up Date',
        dataIndex: 'followUpDateStr'
    },
    {
        title: 'Follow up Trainer',
        dataIndex: 'followUpTrainer'
    },
    {
        title: 'Follow up IDP',
        dataIndex: 'followUpIDPStr'
    }
]

const lifeSkillMonitorRecordsColumns = [
    {
        title: 'Monitor Date',
        dataIndex: 'monitorDateStr',
    },
    {
        title: 'Level',
        dataIndex: 'level'
    },
    {
        title: 'Monitor Stage',
        dataIndex: 'monitorStage'
    },
    {
        title: 'IDP',
        dataIndex: 'idpStr'
    },
    {
        title: 'Monitor Type',
        dataIndex: 'monitorType'
    },
    {
        title: 'Result',
        dataIndex: 'result'
    },
    {
        title: 'NonStandardProcedurePoints',
        dataIndex: 'nonStandardProcedurePoints'
    }
    ,
    {
        title: 'Follow up completed',
        dataIndex: 'followupCompletedStr'
    },
    {
        title: 'Follow up Date',
        dataIndex: 'followUpDateStr'
    },
    {
        title: 'Follow up Trainer',
        dataIndex: 'followUpTrainer'
    },
    {
        title: 'Follow up IDP',
        dataIndex: 'followUpIDPStr'
    }

]


const odpSpeakingMonitorRecordsColumns = [
    {
        title: 'Date of Monitoring',
        dataIndex: 'monitorDate',
        width: 200
    },
    {
        title: 'Reason for Monitoring',
        dataIndex: 'monitoringReason',
        width: 400
    },
    {
        title: 'Monitoring Stage',
        dataIndex: 'monitoringStage',
        width: 200
    },
    {
        title: 'Monitoring Result',
        dataIndex: 'monitoringResult',
        width: 200
    },
    {
        title: 'Follow Up Completed',
        dataIndex: 'followUpCompleted',
        width: 200
    },
    {
        title: 'Follow Up Date',
        dataIndex: 'followUpDate',
        width: 200
    }
    ,
    {
        title: 'Follow Up trainer',
        dataIndex: 'followUpTrainer',
        width: 200
    },
    {
        title: 'Action',
        dataIndex: 'action',
        key: 'action',
        width: 300
    }

]

const useForm = Form.useForm;
export default defineComponent({
    components: {
        LeftOutlined,
        PlusOutlined,
        Pagination,
        CalendarOutlined,
        ExclamationCircleOutlined,
        VNodes: (_, { attrs }) => {
            return attrs.vnodes;
        },
    },
    setup() {
        const followUpDate = ref<string | null>(null);
        const followUpTrainer = ref<string>('');
        const showConfirm = ref<boolean>(false);
        const inputRef = ref();
        const confirmLoading = ref(false);
        const followUpLoading = ref(false);
        const selectOpen = ref(false);
        const isInputClicked = ref(false); // 用于判断 select 是否被点击
        const { currentRoute, push, back } = useRouter();
        const followupShow = ref<boolean>(false);
        const route = unref(currentRoute);
        const examinerName = route.params.examinerName.toString();
        const examinerId = route.params.examinerId.toString();
        const examinerNo = route.params.examinerNo.toString();
        const monitorType = ref<string>('speaking');
        const monitorRecordsTableLoading = ref<boolean>(false);
        const odpMonitorRecordsTableLoading = ref<boolean>(false);
        const speakingMonitorTableSource = ref(new PagedResultOfSpeakingMonitorRecordsDto());
        const searchSpeakingRecordsParames = ref<SearchMonitorRecordsByExaminerRequest>(new SearchMonitorRecordsByExaminerRequest({ examinerId: "", pageIndex: 1, pageSize: 10 }));
        const lifeSkillMonitorTableSource = ref(new PagedResultOfLifeSkillMonitorRecordsDto());
        const searchLifeSkillRecordsParames = ref<SearchMonitorRecordsByExaminerRequest>(new SearchMonitorRecordsByExaminerRequest({ examinerId: "", pageIndex: 1, pageSize: 10 }));
        const speakingODPMonitorTableSource = ref(new PagedResultOfODPMonitorRecordsDto());
        const searchODPRecordsParames = ref<SearchMonitorRecordsByExaminerRequest>(new SearchMonitorRecordsByExaminerRequest({ examinerId: "", pageIndex: 1, pageSize: 10 }));
        const odpSpeakingMonitoringRecord = ref<DeleteMonitorRecordRequest>(new DeleteMonitorRecordRequest({ odpSpeakingMonitoringRecordId: "" }));
        const followUpMonitoringRecord = ref<DeleteMonitorRecordRequest>(new DeleteMonitorRecordRequest({ odpSpeakingMonitoringRecordId: "" }));
        const deleteParames = ref<DeleteMonitorRecordRequest>(new DeleteMonitorRecordRequest({ odpSpeakingMonitoringRecordId: "" }));
        const followUpRequest = ref<FollowUpRequest>(new FollowUpRequest({ odpSpeakingMonitoringRecordId: "", followUpDate: undefined, followUpTrainer: '' }));
        const followUpRequestModel = ref(FollowUpRequest.fromJS({ examinerId: "", followUpDate: undefined, followUpTrainer: undefined }))
        const followUpTrainerInputShow = ref(false);
        const formRef = ref<FormInstance>();
        const examinerList = ref<ExaminerModel[] | undefined>(undefined);
        const selectItem = ref<string>('');
        const items = ref(['jack', 'lucy']);
        const rules = reactive({
            followUpDate: [
                {
                    required: true,
                    message: 'Please select Follow Up Date',

                },
            ],
            followUpTrainer: [
                {
                    required: true,
                    message: 'Please select Follow Up Trainer',
                },
            ]
        });

        const {resetFields, validate, validateInfos } = useForm(followUpRequestModel, rules);

        const getSpeakingMonitorRecords = (() => {
            _Client.monitorRecordsClient.searchSpeakingMonitorRecordByExaminerId(searchSpeakingRecordsParames.value!).then(rep => {
                speakingMonitorTableSource.value = rep;
            }).finally()
        })

        const getLiftSkillMonitorRecords = (() => {
            _Client.monitorRecordsClient.searchLifeSkillMonitorRecordByExaminerId(searchLifeSkillRecordsParames.value!).then(rep => {
                lifeSkillMonitorTableSource.value = rep;
            }).finally(() => monitorRecordsTableLoading.value = false)

        })

        const getODPMonitorRecords = (() => {
            odpMonitorRecordsTableLoading.value = true;
            _Client.monitorRecordsClient.searchODPMonitorRecordByExaminerId(searchODPRecordsParames.value!).then(rep => {
                speakingODPMonitorTableSource.value = rep;
            }).finally(() => odpMonitorRecordsTableLoading.value = false)

        })

        const getmonitorRecords = (() => {
            monitorRecordsTableLoading.value = true;
            getSpeakingMonitorRecords();
            getLiftSkillMonitorRecords();
            getODPMonitorRecords();
            monitorRecordsTableLoading.value = false;
        })

        const showFollowUpModel = ((odpSpeakingMonitoringRecordId: string) => {
            followUpMonitoringRecord.value.odpSpeakingMonitoringRecordId = odpSpeakingMonitoringRecordId;
            _Client.monitorRecordsClient.selectExaminer().then(rep => {
                examinerList.value = rep;
            }).finally(() => monitorRecordsTableLoading.value = false)
            followupShow.value = true;
        })

        const close = ((odpSpeakingMonitoringRecordId: string) => {
            odpSpeakingMonitoringRecordId = odpSpeakingMonitoringRecordId;
            showConfirm.value = false;
        })

        const closeFollowUp = (() => {
            followUpRequestModel.value = FollowUpRequest.fromJS({
                examinerId: "",
                followUpDate: undefined,
                followUpTrainer: ""
            });
            resetFields();
            followupShow.value = false;
        })

        const showdeleteodpSpeakingMonitorRecordModel = ((odpSpeakingMonitoringRecordId: string) => {

            odpSpeakingMonitoringRecord.value.odpSpeakingMonitoringRecordId = odpSpeakingMonitoringRecordId;
            showConfirm.value = true;
        })

        const deleteodpSpeakingMonitorRecord = (() => {
            _Client.monitorRecordsClient.deleteODPMonitorRecordByExaminerId(odpSpeakingMonitoringRecord.value).then(rep => {
                getODPMonitorRecords();
            }).finally(() => showConfirm.value = false)

        })

        const filterOption = (_input: string, _options: { title: string }) => {
            return _options.title.toLowerCase().indexOf(_input.toLowerCase()) >= 0;
        };

        const save = (() => {

            validate().then(async () => {
                followUpLoading.value = true;
                followUpRequest.value.odpSpeakingMonitoringRecordId = followUpMonitoringRecord.value.odpSpeakingMonitoringRecordId;
                followUpRequest.value.followUpTrainer = followUpRequestModel.value.followUpTrainer;
                followUpRequest.value.followUpDate = followUpRequestModel.value.followUpDate;
                _Client.monitorRecordsClient.followUp(followUpRequest.value).then(rep => {
                    followupShow.value = false;
                    message.success('Operation Success.');
                    getODPMonitorRecords();
                }).finally(() => {
                    followUpLoading.value = false
                })
            }).catch(error => {});
        })

        const radioChange = (() => {

            followUpRequestModel.value.followUpTrainer = undefined;
        })

        const changefollowUpDate = (val: any) => {
            if (val != null) {

                followUpRequestModel.value.followUpDate = new Date(dayjs(followUpDate.value!, 'DD/MM/YYYY').format('YYYY-MM-DD'))
            }
            else {
                followUpRequestModel.value.followUpDate = undefined;
            }
        }

        const resetFollowUpRequestModel = () => {





        }

        onMounted(() => {
            searchSpeakingRecordsParames.value.pageIndex = 1;
            searchLifeSkillRecordsParames.value.pageIndex = 1;
            searchSpeakingRecordsParames.value.examinerId = examinerId;
            searchLifeSkillRecordsParames.value.examinerId = examinerId;
            searchODPRecordsParames.value.examinerId = examinerId;
            getmonitorRecords();
        });

        return {
            examinerName,
            examinerNo,
            lifeSkillMonitorRecordsColumns,
            lifeSkillMonitorTableSource,
            monitorType,
            monitorRecordsTableLoading,
            speakingMonitorRecordsColumns,
            speakingMonitorTableSource,
            searchSpeakingRecordsParames,
            searchLifeSkillRecordsParames,
            odpSpeakingMonitorRecordsColumns,
            speakingODPMonitorTableSource,
            searchODPRecordsParames,
            followUpRequestModel,
            showConfirm,
            confirmLoading,
            followUpDate,
            followUpLoading,
            odpSpeakingMonitoringRecord,
            followUpRequest,
            odpMonitorRecordsTableLoading,
            deleteParames,
            followupShow,
            examinerList,
            selectItem,
            items,
            inputRef,
            selectOpen,
            isInputClicked,
            followUpTrainer,
            followUpTrainerInputShow,
            validateInfos,
            rules,
            formRef,
            back,
            getSpeakingMonitorRecords,
            getLiftSkillMonitorRecords,
            getODPMonitorRecords,
            deleteodpSpeakingMonitorRecord,
            showdeleteodpSpeakingMonitorRecordModel,
            close,
            showFollowUpModel,
            filterOption,
            save,
            closeFollowUp,
            radioChange,
            changefollowUpDate,
            resetFollowUpRequestModel
        };
    }
});


